export const environment = {
  production: true,

  VatBackendUrl: 'https://pre.momstjek.dk',

  openIdConnectConfig: {
    stsServer: 'https://r-sts.mstdm.kmd.dk/adfs',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: '692f7da9-c67b-4887-9859-758c500eef5d',
    scope: 'openid',
    responseType: 'code',
    triggerAuthorizationResultEvent: false,
    autoUserinfo: false,
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    customParams: {
      resource: window.location.origin + '/',
      domain_hint: {
        default: 'momstjek.dk',
        kmd: 'kmd.dk',
        bdo: 'bdo.dk',
        billund: 'billund.dk',
        dragoer: 'dragoer.dk',
        favrskov: 'favrskov.dk',
        faxekommune: 'faxekommune.dk', // faxe
        fredensborg: 'fredensborg.dk',
        fredericia: 'fredericia.dk',
        frederikshavn: 'frederikshavn.dk',
        fmk: 'fmk.dk', // faaborg-midtfyn
        goteborg: 'goteborg.se', // goteborgsstad
        greve: 'greve.dk',
        // gribskov: 'gribskov.dk',
        guldborgsund: 'guldborgsund.dk',
        hedensted: 'hedensted.dk',
        helsingor: 'helsingor.dk', // helsingør
        herlev: 'herlev.dk',
        hjoerring: 'hjoerring.dk', // hjørring
        holb: 'holb.dk', // holbæk
        holstebro: 'holstebro.dk',
        horsens: 'horsens.dk',
        hvidovre: 'hvidovre.dk',
        jammerbugt: 'jammerbugt.dk',
        kalundborg: 'kalundborg.dk',
        kerteminde: 'kerteminde.dk',
        kk: 'kk.dk', // koebenhavn
        koege: 'koege.dk',
        langelandkommune: 'langelandkommune.dk', // langeland
        lejre: 'lejre.dk',
        mariagerfjord: 'mariagerfjord.dk',
        middelfart: 'middelfart.dk',
        morsoe: 'morsoe.dk',
        naestved: 'naestved.dk',
        norddjurs: 'norddjurs.dk',
        nordfynskommune: 'nordfynskommune.dk', // nordfyns
        nyborg: 'nyborg.dk',
        odder: 'odder.dk',
        odense: 'odense.dk',
        odsherred: 'odsherred.dk',
        regionh: 'regionh.dk', // regionhovedstaden
        rm: 'rm.dk', // regionmidtjylland
        rn: 'rn.dk', // regionnordjylland
        regionsjaelland: 'regionsjaelland.dk',
        rsyd: 'rsyd.dk', // regionsyddanmark
        rksk: 'rksk.dk', // ringkøbingskjern
        ringsted: 'ringsted.dk',
        roskilde: 'roskilde.dk',
        samsoe: 'samsoe.dk', // samsø
        silkeborg: 'silkeborg.dk',
        skanderborg: 'skanderborg.dk',
        skivekommune: 'skivekommune.dk', // skive
        slagelse: 'slagelse.dk',
        sonderborg: 'sonderborg.dk', // sønderborg
        solrod: 'solrod.dk', // solrød
        soroe: 'soroe.dk', // sorø
        stevns: 'stevns.dk',
        struer: 'struer.dk',
        svendborg: 'svendborg.dk',
        syddjurs: 'syddjurs.dk',
        taarnby: 'taarnby.dk', // tårnby
        vallensbaek: 'vallensbaek.dk', // vallensbæk
        varde: 'varde.dk',
        vejen: 'vejen.dk',
        vejle: 'vejle.dk',
        viborg: 'viborg.dk',
        vordingborg: 'vordingborg.dk',
        aabenraa: 'aabenraa.dk',
        aalborg: 'aalborg.dk',
        aarhus: 'aarhus.dk',
        rudersdal: 'rudersdal.dk',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
